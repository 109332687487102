import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
import swal from 'sweetalert2';
import useLoading from '../../../hooks/useLoading';


import Api from "../../../util/api";
import Util from "../../../util/util";
import { Opacity } from "@material-ui/icons";

const PopularEvents = props => {
    const { startLoading, stopLoading } = useLoading();
    const [state, setState] = useState({
        data: null,
        error: null,
        loading: false,
    });



    console.log(props.eventPath);


    useEffect(() => {
        const consultarEventos = async (e) => {
            if (e) e.preventDefault();
            try {
                startLoading();

                var res = await Api.eventos.list();
                var data = (await res.json()).eventos;
                console.log(data);

                if (data.length === 0) {
                    await swal.fire("Ups!", "No se han encontrado próximos eventos. ", "error");
                }

                if (await Util.requestSuccess(res)) {
                    setState((prevState) => ({
                        ...prevState,
                        data
                    }))
                } else {
                    console.log(data.msg);
                    await swal.fire("Ups!", "Error al consultar eventos. " + data.msg, "error");
                }
            } catch (e) {
                console.log('e');
                console.log(e);
                await Util.showAPIErrorToUser(e);
            }
            stopLoading();
        };

        if (props.eventPath) {
            setState((prevState) => ({
                ...prevState,
                eventPath: props.eventPath
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                eventPath: "/event/"
            }))
        }

        consultarEventos();
    }, []);


    return (
        <section className="tf-section popular-collection bg-home-3" hidden={!state.data || state.data.length === 0}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <h2 className="tf-title style2">Eventos</h2>
                            <div className="heading-line"></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="collection">
                            <Swiper
                                modules={[Navigation, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                navigation
                                scrollbar={{ draggable: true }}
                            >
                                {
                                    state.data && state.data.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <PopularEventsItem item={item} eventPath={state.eventPath} />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
PopularEvents.propTypes = {
    data: PropTypes.array.isRequired,
}

const PopularEventsItem = props => {
    const isEventExpired = new Date() > new Date(props.item.fecha_fin_evento);
    return (

        <div className="swiper-container show-shadow carousel4 button-arow-style" style={isEventExpired ? { opacity: "0.5" } : {}}>
            <Link to={(props.eventPath || "/event/") + props.item.titulo_url}>
                {isEventExpired && (
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px 40px',
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        borderRadius: '12px',
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
                        zIndex: 10,
                        textTransform: 'uppercase',
                        letterSpacing: '2px'
                    }}>
                        Sold Out
                    </div>
                )}
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="slider-item">
                            <div className="sc-card-collection">

                                <div className="media-images-box">
                                    <img src={props.item.url_foto_perfil || (props.item.creador && props.item.creador.foto_perfil) || ""} alt={props.item.titulo} />
                                </div>

                                <div className="card-bottom">
                                    <div className="author">
                                        <div className="sc-author-box style-2 col-3">
                                            <div className="author-avatar" style={{width: "64px"}}> 
                                                <img src={(props.item.creador && props.item.creador.foto_perfil)} alt={props.item.titulo} style={{width: "64px"}} className="avatar" />
                                                <div className="badge"><i className="ripple"></i></div>
                                            </div>
                                        </div>
                                        <div className=" col-6">
                                            <h4><Link to={(props.eventPath || "/event/") + props.item.titulo_url}>{props.item.titulo}</Link></h4>
                                            <div className="infor">
                                                <span>Creado por</span>
                                                <span className="name"><Link to={"/" + (props.item.creador && props.item.creador.nombre)}>{(props.item.creador && props.item.creador.nombre)}</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="author col-3">
                                        <div className="content">
                                            <div className="infor mt-3">
                                                <span>{Util.formatDateShort(props.item.fecha_inicio_evento)} a <br /> {Util.formatDateShort(props.item.fecha_fin_evento)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/login" className="wishlist-button public heart" hidden={true}><span className="number-like">{props.item.wishlist || "0"}</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link >
        </div >

    );
}

export default PopularEvents;
